import { FaEnvelope, FaPhone, FaRegCopy } from "react-icons/fa6";
import high_logo from "../static/logos/highlands-logo-web.png";
import { Link } from "react-router-dom";
import { useRef } from "react";
import moment from "moment";
import { useContext } from "react";
import { ModalContext } from "../context/modalContext";
import PrivacyPolicy from "./mini/PrivacyPolicy";
import CookiePolicy from "./mini/CookiePolicy";

const Footer = () => {
  const customerRef = useRef(null);
  const highlandsRef = useRef(null);
  const currDate = new Date();
  const { dispatch } = useContext(ModalContext);

  const links = ["Privacy Policy", "Cookie Policy"];

  const handleClick = (link) => {
    dispatch({ type: "MODAL_SHOW", payload: link });
  };

  return (
    <div className="bg-[url('/src/static/bg-gray.jpg')] bg-contain mt-auto">
      <div className="max-w-7xl mx-auto py-16 space-y-12 px-5 xl:px-0">
        <div className="flex flex-col lg:flex-row gap-y-10 justify-between lg:gap-x-10">
          <div className="flex flex-col justify-center gap-y-2 lg:w-1/4">
            <Link to="/">
              <img src={high_logo} alt="logo_img" className="object-cover" />
            </Link>
            <p className="text-xs text-highblu font-medium">
              Thanks to the Aberdare rainforest, Highlands Drinks Limited has a
              heritage of quality spanning over 70 years. Just like the
              Aberdare, we pride ourselves in a variety of world class products.
            </p>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 justify-between w-full gap-y-5 flex-1">
            <div className="flex text-sm flex-col space-y-2 px-3 text-highblu">
              <h1 className="font-black">Our Brands</h1>
              <div className="flex flex-col gap-y-2 text-xs font-medium">
                <Link
                  to="/products/highlands-water"
                  className="hover:text-highor w-max"
                >
                  Highlands Water
                </Link>
                <Link
                  to="/products/highlands-cordials"
                  className="hover:text-highor w-max"
                >
                  Highlands Cordials
                </Link>
                <Link to="/products/club" className="hover:text-highor w-max">
                  CLUB
                </Link>
                <Link
                  to="/products/bazuu-energy"
                  className="hover:text-highor w-max"
                >
                  Bazuu Energy
                </Link>
                <Link
                  to="/products/royal-club"
                  className="hover:text-highor w-max"
                >
                  Royal ClUB
                </Link>
              </div>
            </div>
            <div className="flex text-sm flex-col space-y-2 text-highblu">
              <h1 className="font-black">Useful pages</h1>
              <div className="flex flex-col gap-y-2 text-xs font-medium">
                <Link
                  to="/karibu-highlands"
                  className="hover:text-highor w-max"
                >
                  About Us
                </Link>
                <Link to="/workwithus" className="hover:text-highor w-max">
                  Careers
                </Link>
                <Link to="/our-heritage" className="hover:text-highor w-max">
                  Discover Us
                </Link>
                <Link
                  to="/press&newsletter"
                  className="hover:text-highor w-max"
                >
                  News & Events
                </Link>
                <Link to="/FAQs" className="hover:text-highor w-max">
                  FAQ
                </Link>
              </div>
            </div>
            <div className="flex text-sm flex-col text-highblu space-y-2">
              <h1 className="font-black">HQ Contacts</h1>
              <div className="flex flex-col gap-y-2 text-xs">
                <p className="flex items-center gap-x-2">
                  <span>
                    <FaPhone />
                  </span>{" "}
                  +254 (0) 61 2032381/ 2032296
                </p>
                <p className="flex items-center gap-x-2 group">
                  <span>
                    <FaEnvelope />
                  </span>
                  <a href="mailto:highlands@highlandske.com" ref={highlandsRef}>
                    highlands@highlandske.com
                  </a>
                  <FaRegCopy
                    className="hidden group-hover:flex cursor-pointer hover:text-highor"
                    title="Copy Email"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        customerRef.current.innerText
                      )
                    }
                  />
                </p>
              </div>
            </div>
            <div className="flex text-sm flex-col text-highblu space-y-2">
              <h1 className="font-black">Customer Care Center</h1>
              <div className="flex flex-col gap-y-2 text-xs">
                <p className="flex items-center gap-x-2">
                  <span>
                    <FaPhone />
                  </span>{" "}
                  +254 (0) 790 490 529
                </p>
                <p className="flex items-center gap-x-2 group">
                  <span>
                    <FaEnvelope />
                  </span>
                  <a
                    href="mailto:customercare@highlandske.com"
                    ref={customerRef}
                  >
                    customercare@highlandske.com
                  </a>
                  <FaRegCopy
                    className="hidden group-hover:flex cursor-pointer hover:text-highor"
                    title="Copy Email"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        customerRef.current.innerText
                      )
                    }
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row items-baseline justify-between text-sm text-highblu border-t border-t-gray-100 pt-5">
          <p className="text-xs shrink-0">
            ©{moment(currDate).format("YYYY")} Highlands Drinks Limited.
          </p>
          <div className="flex items-center gap-x-3 text-xs">
            {Object.values(links).map((link) => (
              <p
                className="hover:underline cursor-pointer"
                onClick={() => handleClick(link)}
              >
                {link}
              </p>
            ))}
            <PrivacyPolicy />
            <CookiePolicy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
