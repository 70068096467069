import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { ModalContext } from "../../context/modalContext";
import { useEffect } from "react";
import { sanityClient } from "../../lib/sanity";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const CookiePolicy = () => {
  const { state, dispatch } = useContext(ModalContext);
  const [cookiePolicy, setCookiePolicy] = useState();

  const handleClose = () => {
    dispatch({ type: "MODAL_HIDE" });
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "cookiePolicy"][0]{
				slug,
        cookiePolicyDoc,
        "cookiePolicyURL": cookiePolicyDoc.asset->url,
			}`
      )
      .then((data) => setCookiePolicy(data))
      .catch(console.error);
  }, []);

  return (
    <>
      {cookiePolicy && (
        <Modal
          disablePortal
          open={state.modalShow === "Cookie Policy"}
          onClose={handleClose}
          closeAfterTransition
          className="mx-5 xl:mx-0 text-base text-black"
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={state.modalShow === "Cookie Policy"}>
            <Box
              sx={style}
              className="w-full h-[40rem] 2xl:h-[46rem] lg:w-[60rem] xl:w-[70rem] overflow-y-scroll"
            >
              <FaTimes
                className="absolute top-2 right-4 cursor-pointer"
                onClick={handleClose}
              />
              <object
                data={cookiePolicy.cookiePolicyURL}
                type="application/pdf"
                title={cookiePolicy.cookiePolicyDoc.cookiePolicy}
                frameBorder="0"
                width="100%"
                height="100%"
                sandbox="allow-scripts allow-same-origin"
                seamless
              >
                <p>Failed to load PDF</p>
              </object>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default CookiePolicy;
